import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardGuard } from './shared/guards/auth-guards.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./layouts/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./layouts/home/home.module').then((m) => m.HomeModule),
  },
  // { path: 'mainhome', loadChildren: () => import('./layouts/main-home/main-home.module').then(m => m.MainHomeModule) },
  {
    path: '**',
    redirectTo: 'dashboard',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      relativeLinkResolution: 'corrected',
      scrollPositionRestoration: 'top',
      useHash: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}

// import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/functions';

export const environment = {
  // apiUrl: 'http://localhost:3000/api',
  apiUrl: 'https://es.thegangafoundation.org/api',

  // apiUrl: 'http://3.141.23.70:3000/api',
  Url: 'https://es.thegangafoundation.org/',
  production: false,
};
// export const app = firebase.initializeApp(environment);
// export const functions = app.functions('us-central1');
